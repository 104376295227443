import React from "react";
import { Col, Row } from "react-bootstrap";
import {FaPython} from 'react-icons/fa'
import {SiCplusplus} from 'react-icons/si'
import {AiOutlineLineChart} from 'react-icons/ai'
import {FaCode} from 'react-icons/fa'
import {SiTensorflow} from 'react-icons/si'
import {GiProcessor} from 'react-icons/gi'
import {BiBrain} from 'react-icons/bi'
const listOfIcons = [
  <FaPython/>,
  <SiCplusplus/>,
  <AiOutlineLineChart/>,
  <FaCode/>,
  <SiTensorflow/>,
  <GiProcessor/>,
  <BiBrain/> 
]


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {listOfIcons.map((item, _idx) => (
        <Col xs={4} md={2} className="tech-icons">
        {item}
      </Col>
      ))}
      
    </Row>
  );
}

export default Techstack;
