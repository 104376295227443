import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          As a passionate enthusiast of Machine Learning, I have a deep fascination for the field and its potential applications. My journey into ML started when I discovered the power of neural networks and advanced machine learning algorithms, igniting my curiosity about the capabilities of computers to learn from data and make informed decisions.

Since that pivotal moment, I have been fully immersed in the study of Machine Learning, continually seeking opportunities to expand my knowledge and skills in this ever-evolving domain. I am always excited to explore new concepts, experiment with different techniques, and contribute to the advancement of ML technology. This enthusiasm drives me to contribute to innovative and impactful projects and collaborate with fellow ML enthusiasts to create a better future through artificial intelligence.</p>
          <p style={{ color: "rgb(155 126 172)" }}>
          "Machine Learning is like a painter's brush, a powerful tool for creating intelligent solutions to complex problems."          </p>
          <footer className="blockquote-footer">Atharv</footer>
        
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
